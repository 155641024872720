.products {
    background-color: #2b2b2b; /* Dark background */
    color: #e5e5e5;
    padding:1px 20px;
  }
  
  .products h2 {
    font-size: 40px;
    margin-bottom: 30px;
    text-align: center;
    color: #f39c12; /* Bright accent color */
  }
  
  .product-list {
    display: flex;
    flex-direction: column; /* Vertical stacking of products */
    gap: 20px; /* Space between each product */
  }
  
  .product-card {
    display: flex; /* Horizontal layout inside the card */
    background: #2d2d2d; /* Dark card background */
    color: #e5e5e5;
    border-radius: 12px;
    padding: 45px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7); /* Deep shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transition */
    width: 100%; /* Full width of the container */
    max-width: 800px; /* Max width to make it more narrow */
    margin: 0 auto; /* Center the product card */
    align-items: center;
  }
  
  .product-card img {
    width: 250px; /* Smaller width for the image */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    margin-right: 70px; /* Space between image and text */
  }
  
  .product-card .product-info {
    flex: 1; /* Take up remaining space */
  }
  
  .product-card h3 {
    font-family: 'Playfair Display', serif;
    font-size: 24px;
    margin: 10px 0;
    font-weight: 600;
  }
  
  .product-card p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    margin: 10px 0;
  }
  
  .product-card .price {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    color: #f39c12;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .product-card button {
    background-color: #f39c12;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }
  
  .product-card button:hover {
    background-color: #e67e22; /* Darker accent color on hover */
    transform: scale(1.05); /* Slight scale effect */
  }
  