.contact {
    background-color: #2b2b2b; /* Dark background */
    color: #e5e5e5;
    padding: 50px 20px;
    text-align: center;
  }
  
  .contact h2 {
    font-size: 40px;
    margin-bottom: 30px;
    color: #f39c12; /* Bright accent color */
  }
  
  .contact-info {
    margin-bottom: 30px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .contact-item i {
    font-size: 24px;
    color: #f39c12; /* Bright accent color */
    margin-right: 10px;
  }
  
  .contact-item p {
    font-size: 18px;
    margin: 0;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-form input,
  .contact-form textarea {
    background-color: #2d2d2d; /* Dark background */
    color: #e5e5e5;
    border: 1px solid #444;
    border-radius: 8px;
    padding: 15px;
    font-size: 16px;
  }
  
  .contact-form button {
    background-color: #f39c12;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }
  
  .contact-form button:hover {
    background-color: #e67e22; /* Darker accent color on hover */
    transform: scale(1.05); /* Slight scale effect */
  }
  