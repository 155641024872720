.intro {
    background-color: #2b2b2b; /* Dark background */
    color: #e5e5e5;
    padding: 100px 20px;
    text-align: center;
  }
  
  .intro h2 {
    font-size: 48px;
    color: #f39c12; /* Bright accent color */
  }
  
  .intro p {
    font-size: 24px;
  }
  