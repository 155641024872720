.about-us {
  background-color: #2b2b2b; /* Dark background */
  color: #e5e5e5;
  padding: 50px 20px;
  text-align: center;
}

.about-us h2 {
  font-family: 'Playfair Display', serif;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #f39c12;
}

.about-us p {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto 30px;
  font-weight: 400;
}

.team {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.team-member {
  background: #333; /* Dark background for team members */
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5); /* Soft shadow */
  max-width: 300px;
  width: 100%;
  text-align: center;
}

.team-member img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.team-info {
  color: #e5e5e5;
}

.team-info h3 {
  font-size: 24px;
  margin: 10px 0;
}

.team-info a {
  display: block;
  font-size: 18px;
  margin: 5px 0;
  color: #f39c12; /* Bright accent color */
  text-decoration: none;
}

.team-info a:hover {
  text-decoration: underline;
}

.team-info p {
  font-size: 18px;
}

