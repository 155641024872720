body {
  /* margin: 0;
  font-family: 'Suse', sans-serif;
  color: #e5e5e5;
  background-color: #1e1e1e; Dark background for the whole page */
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #e5e5e5;
  background-color: #2b2b2b;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  color: #f39c12; /* Bright accent color for headings */
  font-weight: 600;
  margin-bottom: 20px;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #e5e5e5;
}